import { Spinner } from 'shared/Spinner';
import styled from 'styled-components';

const Wrapper = styled.main<{ bgColor?: string }>`
  width: 100%;
  height: 100vh;
  position: relative;
  background: var(--bg-color, var(--color-white));
`;

const Container = styled.section`
  display: flex;
  padding-top: 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface Props {
  bgColor?: string;
}

const Loading = ({ bgColor }: Props) => (
  <Wrapper style={{ '--bg-color': bgColor }}>
    <Container>
      <Spinner size="large" color="var(--color-primary-500)" />
    </Container>
  </Wrapper>
);

export default Loading;
