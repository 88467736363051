import Link from 'next/link';
import React, { ReactNode } from 'react';
import { Spinner } from '../Spinner';
import { UrlObject } from 'url';
import Icon, { IconNames } from '../Icons';
import clsx from 'clsx';
import { getIconSizeClassName, getSizeClassName } from './button-helpers';

declare type Url = string | UrlObject;

export type ButtonSize = 'small' | 'medium' | 'large';

export interface ButtonSizes {
  default: ButtonSize;
  laptop?: ButtonSize;
  tablet?: ButtonSize;
  phone?: ButtonSize;
}

type ButtonVariant =
  | 'fill'
  | 'ghost'
  | 'ghost-white'
  | 'transparent'
  | 'black-outline';

const variants: Record<ButtonVariant, string> = {
  fill: 'btn-fill',
  ghost: 'btn-ghost-red',
  'ghost-white': 'btn-ghost-white',
  transparent: 'btn-fill-white',
  'black-outline': 'btn-black-outline',
};

interface DefaultBaseProps {
  size: ButtonSize | ButtonSizes;
  variant: ButtonVariant;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  dataTest?: string;
  isLoading?: boolean;
  shallow?: boolean;
  children: ReactNode | ReactNode[];
  onMouseEnter?: () => void;
  onEventHandler?: () => void;
}

interface BaseProps extends DefaultBaseProps {
  withIcon?: never;
  iconId?: never;
  iconLeft?: never;
}

interface ButtonProps {
  link?: false;
  href?: never;
  onClick?: () => void;
}

interface LinkProps {
  link: true;
  href: Url;
  onClick?: never;
}

type DefaultProps = BaseProps & (ButtonProps | LinkProps);

interface WithIconBaseProps extends DefaultBaseProps {
  withIcon: true;
  iconId: IconNames;
  iconLeft?: boolean;
}

type WithIconProps = WithIconBaseProps & (ButtonProps | LinkProps);

type Props = DefaultProps | WithIconProps;

const Button = ({
  size,
  variant,
  disabled,
  fullWidth,
  type,
  onClick,
  link,
  href,
  withIcon,
  iconId,
  children,
  iconLeft,
  isLoading,
  shallow,
  onMouseEnter,
  onEventHandler,
}: Props) => {
  const variantClassName = variants[variant];
  const sizeClassName = getSizeClassName(size);

  return link && href ? (
    <Link href={href} shallow={shallow}>
      <a
        onMouseEnter={onMouseEnter}
        className={clsx(
          'no-underline',
          variantClassName,
          sizeClassName,
          fullWidth ? 'w-full' : 'w-fit',
        )}
      >
        <ButtonContent
          withIcon={withIcon}
          iconId={iconId}
          iconLeft={iconLeft}
          size={size}
        >
          {children}
        </ButtonContent>
      </a>
    </Link>
  ) : (
    <button
      className={clsx(
        variantClassName,
        sizeClassName,
        fullWidth ? 'w-full' : 'w-fit',
      )}
      disabled={disabled}
      type={type}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {isLoading ? (
        <Spinner size={size} />
      ) : (
        <ButtonContent
          withIcon={withIcon}
          iconId={iconId}
          iconLeft={iconLeft}
          size={size}
        >
          {children}
        </ButtonContent>
      )}
    </button>
  );
};

export default Button;

const ButtonContent = ({
  withIcon,
  iconId,
  iconLeft,
  size,
  children,
}: {
  withIcon: boolean | undefined;
  iconId: IconNames | undefined;
  iconLeft?: boolean;
  size: ButtonSize | ButtonSizes;
  children: ReactNode | ReactNode[];
}) => {
  const iconSizeClassName = getIconSizeClassName(size);

  return (
    <>
      {withIcon && iconId && iconLeft ? (
        <Icon id={iconId} className={iconSizeClassName} />
      ) : null}
      {children}
      {withIcon && iconId && !iconLeft ? (
        <Icon id={iconId} className={iconSizeClassName} />
      ) : null}
    </>
  );
};
