import styled from 'styled-components';
import { Carousel as CarouselType } from '@solin-fitness/types';
import { CarouselCard } from './CarouselCard';
import UnstyledButton from 'shared/UnstyledButton';
import Icon, { IconNames } from 'shared/Icons';
import { useEffect, useState, useRef } from 'react';
import { motion, Variants } from 'framer-motion';
import { isMobile } from 'react-device-detect';

const Carousel = ({ cards }: { cards: CarouselType[] }) => {
  const outerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  useEffect(() => {
    if (ref.current && outerRef.current) {
      const scrollWidth = ref.current.scrollWidth;
      const clientWidth = outerRef.current.clientWidth;
      if (scrollWidth > clientWidth) {
        setShowRight(true);
      }
    }
  }, [ref, outerRef]);

  const scrollRight = () => {
    if (ref.current) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft + 317,
        behavior: 'smooth',
      });
    }
  };

  const scrollLeft = () => {
    if (ref.current) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft - 317,
        behavior: 'smooth',
      });
    }
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
      },
    },
  } as unknown as Variants;

  const item = {
    hidden: { opacity: 0, y: isMobile ? 0 : 400 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <OuterContainer ref={outerRef}>
      <Container
        ref={ref}
        onScroll={(e) => {
          const target = e.currentTarget;
          if (target.scrollLeft === 0) {
            setShowLeft(false);
          } else {
            setShowLeft(true);
          }

          if (target.scrollLeft + target.clientWidth === target.scrollWidth) {
            setShowRight(false);
          } else {
            setShowRight(true);
          }
        }}
        variants={container}
        initial="hidden"
        animate="show"
      >
        {cards.map((card) => (
          <Item
            key={card.id}
            variants={item}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}
          >
            <CarouselCard card={card} />
          </Item>
        ))}
      </Container>
      <LeftGradient>
        {showLeft ? (
          <IconWrap onClick={scrollLeft}>
            <Icon
              id={IconNames.chevronLeft}
              color="var(--color-black-500)"
              width={27}
              height={27}
            />
          </IconWrap>
        ) : null}
      </LeftGradient>
      <RightGradient>
        {showRight ? (
          <IconWrap onClick={scrollRight}>
            <Icon
              id={IconNames.chevronRight}
              color="var(--color-black-500)"
              width={27}
              height={27}
            />
          </IconWrap>
        ) : null}
      </RightGradient>
    </OuterContainer>
  );
};

export { Carousel };

const OuterContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const Container = styled(motion.div)`
  max-width: 100%;
  width: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 64px;
  scroll-padding: 64px;
  margin-bottom: 48px;
  scroll-snap-type: x mandatory;
  white-space: nowrap;

  /* hide actual scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding: 0 24px;
    margin-bottom: 48px;
    scroll-padding: 24px;
  }
`;

const Item = styled(motion.div)`
  display: inline-block;
  scroll-snap-align: start;

  &:not(:last-child) {
    padding-right: 16px;
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    scroll-snap-align: center;
  }
`;

const RightGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, transparent, hsl(0, 0%, 0%));

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: none;
  }
`;

const LeftGradient = styled(RightGradient)`
  right: unset;
  left: 0;
  bottom: 0;
  background: linear-gradient(to left, transparent, hsl(0, 0%, 0%));

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: none;
  }
`;

const IconWrap = styled(UnstyledButton)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  flex-shrink: 0;
  background: var(--color-white);
  opacity: 0.7;
  box-shadow: 1px 2px 4px 0 hsla(0, 0%, 0%, 0.5);
`;
