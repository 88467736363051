import { useQuery } from 'react-query';
import { LandingPageContent } from '@solin-fitness/types';
import { api, Methods } from 'services/api';

const landingPageKeys = {
  all: ['landingPage'] as const,
};

const getLandingPage = async () => api<LandingPageContent>(Methods.get, '/cms');

const useLandingPage = () => useQuery(landingPageKeys.all, getLandingPage);

export { getLandingPage, useLandingPage, landingPageKeys };
