import styled from 'styled-components';

const Header = styled.h2`
  font-family: 'HKGrotesk';
  font-size: 42px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--color-white);
  text-align: center;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    text-align: revert;
  }
`;

const SubHeader = styled.p`
  max-width: 660px;
  font-family: 'HKGrotesk';
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--color-white);
  text-align: center;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    text-align: revert;
  }
`;

const SectionLink = styled.a`
  text-decoration: none;
  color: var(--color-black-500);
  background: var(--color-white);
  border-radius: 999px;
  padding: 9px 16px;
  font-family: 'HKGrotesk';
  font-size: 14px;
  font-weight: 700;
`;

export { Header, SubHeader, SectionLink };
