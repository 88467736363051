import styled from 'styled-components';
import { Carousel } from '@solin-fitness/types';
import buildImageUrl from 'cloudinary-build-url';
import { Instagram } from 'react-feather';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { MediaContextProvider, Media } from 'media';
import { isMobile } from 'react-device-detect';

const CarouselCard = ({ card }: { card: Carousel }) => {
  const [hover, setHover] = useState(false);
  const ctaVariants = {
    hover: { opacity: 1 },
    base: { opacity: 0 },
  };
  const primaryVariants = {
    hover: { y: -20 },
    base: { y: 0 },
  };
  const secondaryVariants = {
    hover: { y: -20 },
    base: { y: 0 },
  };
  const animate = hover && !isMobile ? 'hover' : 'base';

  return (
    <Link href={card.link} passHref>
      <a>
        <MediaContextProvider>
          <Container>
            <AspectRatio.Root ratio={9 / 16}>
              <img
                src={buildImageUrl(card.image, {})}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />
              <Content
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <Media greaterThanOrEqual="md">
                  <CTA animate={animate} variants={ctaVariants}>
                    {card.cta}
                  </CTA>
                </Media>
                <Media lessThan="md">
                  <CTA>{card.cta}</CTA>
                </Media>
                <Primary
                  animate={animate}
                  variants={primaryVariants}
                  transition={{ delay: hover ? 0.0 : 0.08 }}
                >
                  {card.primaryText}
                </Primary>
                <SecondaryWrap
                  animate={animate}
                  variants={secondaryVariants}
                  transition={{ delay: hover ? 0.08 : 0.0 }}
                >
                  <Secondary>{card.secondaryText}</Secondary>
                  {card.followers ? (
                    <SecondaryInlineWrap>
                      <Instagram color="var(--color-white)" size={18} />
                      <Secondary>{card.followers}</Secondary>
                    </SecondaryInlineWrap>
                  ) : null}
                </SecondaryWrap>
              </Content>
            </AspectRatio.Root>
          </Container>
        </MediaContextProvider>
      </a>
    </Link>
  );
};

export { CarouselCard };

const Container = styled.div`
  width: 319px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    width: 300px;
  }
`;

const CTA = styled(motion.p)`
  font-family: 'HKGrotesk';
  display: none;
  padding: 12px 20px;
  border: 1px solid var(--color-white);
  border-radius: 999px;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-white);
  text-align: center;
  text-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.25);
  transform: translateY(-20px);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    transform: translateY(0px);
    display: block;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
  padding: 32px 26px;

  @media (hover: hover) and (pointer: fine) {
    &:hover ${CTA} {
      display: block;
    }
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding: 18px;
  }
`;

const Primary = styled(motion.h4)`
  font-family: 'HKGrotesk';
  font-size: 20px;
  font-weight: 600;
  color: #fdacac;
  text-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.25);
  text-align: center;
`;

const Secondary = styled(motion.h6)`
  font-family: 'HKGrotesk';
  font-size: 16px;
  font-weight: 500;
  color: var(--color-white);
  text-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.25);
`;

const SecondaryWrap = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const SecondaryInlineWrap = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;
