import Link from 'next/link';
import { LOGIN, PRIVACY, TERMS } from '../../constants/routes';
import clsx from 'clsx';
import { ReactChild } from 'react';

const Footer = ({ theme = 'dark' }: { theme?: 'light' | 'dark' }) => {
  const isDark = theme === 'dark';
  return (
    <>
      <footer className="px-5 py-16 flex flex-col gap-3 sm:flex-row sm:justify-center sm:gap-6 sm:pb-10 sm:pt-10">
        <LinkItem isDark={isDark}>Solin &#169; 2022</LinkItem>
        <LinkItem href="/" isDark={isDark}>
          Home
        </LinkItem>
        <LinkItem href={LOGIN} isDark={isDark}>
          Sign In
        </LinkItem>

        <LinkItem href="mailto:info@solinfitness.com" external isDark={isDark}>
          Contact
        </LinkItem>

        <LinkItem href={PRIVACY} isDark={isDark}>
          Privacy Policy
        </LinkItem>

        <LinkItem href={TERMS} isDark={isDark}>
          Terms
        </LinkItem>
      </footer>
    </>
  );
};

export { Footer };

const LinkItem = ({
  href,
  external,
  children,
  isDark,
  className,
}: {
  href?: string;
  external?: boolean;
  children: ReactChild;
  isDark: boolean;
  className?: string;
}) => {
  return href && !external ? (
    <Link href={href}>
      <a
        className={clsx(
          'type-body-2-bold no-underline',
          isDark ? 'text-white' : 'text-gray-700',
          className,
        )}
      >
        {children}
      </a>
    </Link>
  ) : href ? (
    <a
      href={href}
      target="_blank"
      className={clsx(
        'type-body-2-bold no-underline',
        isDark ? 'text-white' : 'text-gray-700',
        className,
      )}
    >
      {children}
    </a>
  ) : (
    <p
      className={clsx(
        'type-body-2-bold no-underline cursor-default',
        isDark ? 'text-white' : 'text-gray-700',
        className,
      )}
    >
      {children}
    </p>
  );
};
