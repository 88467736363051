import styled from 'styled-components';
import Button from 'shared/Button';
import Portal from '@reach/portal';
import { SETTINGS } from '../../constants/routes';
import Typography, { TypeVariant } from 'shared/Typography';
import { useRouter } from 'next/router';
import useUserMetadata from '../../hooks/useUserMetadata';
import { SubscriptionStatus } from '@solin-fitness/types';
import { User } from 'types/auth';

const AccountPastDueAlert = ({ user }: { user: User | null }) => {
  const router = useRouter();
  const isSignedIn = !!user;
  const { data } = useUserMetadata(isSignedIn);

  if (router.asPath.includes('/live/')) {
    return null;
  }

  if (data) {
    const pastDueSubscriptions = data.subscriptions.filter(
      (sub) =>
        (sub.status === SubscriptionStatus.pastDue ||
          sub.status === SubscriptionStatus.incomplete) &&
        !sub.cancelAtPeriodEnd,
    );

    if (pastDueSubscriptions.length === 0) {
      return null;
    }

    const accounts = pastDueSubscriptions.map((sub) => sub.pageName);
    const accountNames =
      accounts.length > 1
        ? accounts.slice(0, -1).join(', ') + ' and ' + accounts.slice(-1)
        : accounts[0];
    const isMultiple = accounts.length > 1;

    return (
      <Portal>
        <Container>
          <Wrap>
            <Typography
              variant={TypeVariant.body02}
              as="p"
              color="var(--color-white)"
            >
              Your subscription{isMultiple ? "'s" : ''} to {accountNames}{' '}
              {isMultiple ? 'are' : 'is'} past due and will be cancelled soon.
              Please update your credit card immediately to keep access. Once
              your card is updated, it may take a day or two for this message to
              go away. Please email{' '}
              <EmailLink href="mailto:support@solinfitness.com" target="_blank">
                support@solinfitness.com
              </EmailLink>{' '}
              with any questions.
            </Typography>
            <Button
              size="medium"
              link
              href={`${SETTINGS}#payment`}
              variant="fill"
            >
              Update card
            </Button>
          </Wrap>
        </Container>
      </Portal>
    );
  }

  return null;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 15px;
  left: 0;
`;

export const Wrap = styled.div`
  background: #537089;
  padding: 20px 25px;
  border-radius: 40px;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 1100px) {
    width: calc(100vw - 40px);
  }

  @media ${(p) => p.theme.queries.tabletAndDown} {
    gap: 16px;
    flex-direction: column;
  }
`;

const EmailLink = styled.a`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  word-break: normal;
`;

export default AccountPastDueAlert;
