import clsx from 'clsx';
import { ButtonSize, ButtonSizes } from './Button';

const sizes: Record<ButtonSize, string> = {
  small: 'btn-sm',
  medium: 'btn-md',
  large: 'btn-lg',
};

const iconSizes: Record<ButtonSize, string> = {
  small: 'icon-sm',
  medium: 'icon-md',
  large: 'icon-lg',
};

const findDefault = (size: ButtonSizes) => {
  if (size.phone) {
    return size.phone;
  }
  if (size.tablet) {
    return size.tablet;
  }
  if (size.laptop) {
    return size.laptop;
  }
  return size.default;
};

const addPrefix = (className: string, prefix: 'sm' | 'md' | 'lg') => {
  // split at spaces and then add prefix then join
  const classes = className.split(' ');
  const newClasses = classes.map((name) => `${prefix}:${name}`);
  return newClasses.join(' ');
};

const getSizeClassName = (size: ButtonSize | ButtonSizes) => {
  if (typeof size === 'string') {
    return sizes[size];
  }

  const defaultSize = findDefault(size);

  return clsx(
    sizes[defaultSize],
    size.tablet ? addPrefix(sizes[size.tablet], 'sm') : null,
    size.default ? addPrefix(sizes[size.default], 'md') : null,
    size.laptop ? addPrefix(sizes[size.laptop], 'lg') : null,
  );
};

const getIconSizeClassName = (size: ButtonSize | ButtonSizes) => {
  if (typeof size === 'string') {
    return iconSizes[size];
  }

  const defaultSize = findDefault(size);

  return clsx(
    iconSizes[defaultSize],
    size.tablet ? addPrefix(iconSizes[size.tablet], 'sm') : null,
    size.default ? addPrefix(iconSizes[size.default], 'md') : null,
    size.laptop ? addPrefix(iconSizes[size.laptop], 'lg') : null,
  );
};

export { getSizeClassName, getIconSizeClassName };
